var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "collection-detail" },
    [
      _c("div", { staticClass: "page-color" }, [
        _c(
          "div",
          { staticClass: "page-width" },
          [
            _c("h2", [_vm._v("订单信息")]),
            _c(
              "el-row",
              { attrs: { gutter: 24 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: "订单编号",
                        "hide-details": "auto",
                        disabled: "",
                        outlined: "",
                        dense: true,
                        height: 32
                      },
                      model: {
                        value: _vm.orderDetails.orderNo,
                        callback: function($$v) {
                          _vm.$set(_vm.orderDetails, "orderNo", $$v)
                        },
                        expression: "orderDetails.orderNo"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: "报名商品",
                        "hide-details": "auto",
                        disabled: "",
                        outlined: "",
                        dense: true,
                        height: 32
                      },
                      model: {
                        value: _vm.orderDetails.goodsName,
                        callback: function($$v) {
                          _vm.$set(_vm.orderDetails, "goodsName", $$v)
                        },
                        expression: "orderDetails.goodsName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: "学员姓名",
                        "hide-details": "auto",
                        disabled: "",
                        outlined: "",
                        dense: true,
                        height: 32
                      },
                      model: {
                        value: _vm.orderDetails.userName,
                        callback: function($$v) {
                          _vm.$set(_vm.orderDetails, "userName", $$v)
                        },
                        expression: "orderDetails.userName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: "学习账号",
                        "hide-details": "userPhone",
                        disabled: "",
                        outlined: "",
                        dense: true,
                        height: 32
                      },
                      model: {
                        value: _vm.orderDetails.userPhone,
                        callback: function($$v) {
                          _vm.$set(_vm.orderDetails, "userPhone", $$v)
                        },
                        expression: "orderDetails.userPhone"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: "应收金额",
                        "hide-details": "auto",
                        disabled: "",
                        outlined: "",
                        dense: true,
                        height: 32
                      },
                      model: {
                        value: _vm.orderDetails.payPrice,
                        callback: function($$v) {
                          _vm.$set(_vm.orderDetails, "payPrice", $$v)
                        },
                        expression: "orderDetails.payPrice"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "page-color", staticStyle: { "margin-top": "12px" } },
        [
          _c(
            "div",
            { staticClass: "page-width" },
            [
              _c("h2", [_vm._v("收款信息")]),
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    "tooltip-effect": "dark",
                    border: "",
                    stripe: "",
                    size: "mini",
                    "element-loading-text": "拼命加载中",
                    "row-style": { height: "0px" },
                    "cell-style": { padding: "0px" }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "凭证照片",
                      prop: "deptName",
                      "min-width": "100"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "img-box" },
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "96px",
                                    height: "54px"
                                  },
                                  attrs: {
                                    src: scope.row.photoUrl,
                                    "preview-src-list": [scope.row.photoUrl]
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "支付系统流水",
                      prop: "serialNumber",
                      width: "180"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "收款方式信息",
                      prop: "paymentMethodName",
                      width: "200"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("div", [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.paymentMethodName))
                              ]),
                              scope.row.accountName
                                ? _c("div", [
                                    _vm._v(_vm._s(scope.row.accountName))
                                  ])
                                : _vm._e(),
                              _c("div", [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.paymentPlatformName))
                                ]),
                                _vm._v(" "),
                                scope.row.installmentNumber
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-left": "10px" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.installmentNumber + "期"
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]),
                              _c("div", [
                                scope.row.companyDiscountRate
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          "贴息率：" +
                                            scope.row.companyDiscountRate
                                        )
                                      )
                                    ])
                                  : _vm._e(),
                                scope.row.companyDiscountMoney
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-left": "10px" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            "贴息金额：" +
                                              scope.row.companyDiscountMoney
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]),
                              scope.row.guaranteeMoney
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        "保证金：" + scope.row.guaranteeMoney
                                      )
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "收款金额",
                      prop: "money",
                      "min-width": "100"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("div", [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "14px",
                                    "font-weight": "bold",
                                    color: "#F54955"
                                  }
                                },
                                [_vm._v(_vm._s(scope.row.money + "元"))]
                              ),
                              scope.row.loanReceivedMoney
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        "到账：" + scope.row.loanReceivedMoney
                                      )
                                    )
                                  ])
                                : _vm._e(),
                              scope.row.loanDate
                                ? _c("div", [
                                    _vm._v(
                                      "放款时间：" +
                                        _vm._s(
                                          _vm._f("formatDate")(
                                            scope.row.loanDate,
                                            2
                                          )
                                        )
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "提交信息",
                      prop: "deptName",
                      "min-width": "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("div", [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.assigneeName))
                              ]),
                              _c("p", [_vm._v(_vm._s(scope.row.startTime))])
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "凭证备注", prop: "remark", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.remark
                              ? _c(
                                  "div",
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.remark.length > 20
                                            ? scope.row.remark.slice(0, 20) +
                                                "..."
                                            : scope.row.remark
                                        ) +
                                        " "
                                    ),
                                    scope.row.remark.length > 20
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass: "hq-button",
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.seeAll(
                                                  scope.row.remark
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("查看全部")]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "当前办理人",
                      prop: "currentHandlerName",
                      width: "180"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "审核状态",
                      prop: "checkStatus",
                      width: "80"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                style: {
                                  color:
                                    scope.row.checkStatus === 1
                                      ? "#666"
                                      : scope.row.checkStatus === 2
                                      ? "#00AB84"
                                      : "#F54955"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("convert")(
                                      scope.row.checkStatus,
                                      _vm.checkList
                                    )
                                  )
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "操作",
                      prop: "deptName",
                      width: "300",
                      fixed: "right"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm.loginUser.dataScope == 1 ||
                            _vm.loginUser.dataScope == 2 ||
                            scope.row.currentHandlerId == _vm.loginUser.tyUserId
                              ? _c(
                                  "span",
                                  [
                                    scope.row.activityId === "_6"
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "small",
                                              type: "primary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.passClick(scope.row)
                                              }
                                            }
                                          },
                                          [_vm._v("通过")]
                                        )
                                      : _vm._e(),
                                    scope.row.activityId === "_6"
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "small",
                                              type: "error"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.rejectClick(
                                                  scope.row
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("驳回")]
                                        )
                                      : _vm._e(),
                                    scope.row.activityId === "_5"
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "small",
                                              type: "primary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.editClick(scope.row)
                                              }
                                            }
                                          },
                                          [_vm._v("编辑")]
                                        )
                                      : _vm._e(),
                                    scope.row.isDelete
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "small",
                                              type: "danger"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteClick(
                                                  scope.row
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("删除")]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "go-back" },
        [_c("el-button", { on: { click: _vm.backClick } }, [_vm._v("返回")])],
        1
      ),
      _c(
        "HqModal",
        {
          attrs: { visible: _vm.rejectModel, title: "提示" },
          on: { "on-cancel": _vm.cancel }
        },
        [
          _c(
            "el-form",
            {
              ref: "rejectForm",
              attrs: { model: _vm.rejectForm, rules: _vm.rejectRules }
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "comment" } },
                [
                  _c("v-textarea", {
                    attrs: {
                      outlined: "",
                      height: 80,
                      name: "input-7-4",
                      label: "驳回原因",
                      placeholder: "请输入驳回原因"
                    },
                    model: {
                      value: _vm.rejectForm.comment,
                      callback: function($$v) {
                        _vm.$set(_vm.rejectForm, "comment", $$v)
                      },
                      expression: "rejectForm.comment"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("EditComponent", {
        ref: "edit",
        attrs: {
          loanPlatformList: _vm.loanPlatformList,
          collectTypeList: _vm.collectTypeList,
          accountList: _vm.accountList,
          method: _vm.getqueryPayVoucherDetailsByProcessInstanceId
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "凭证备注",
            visible: _vm.dialogVisible,
            center: "",
            width: "35%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "max-height": "300px",
                overflow: "hidden",
                "overflow-y": "auto"
              }
            },
            [_vm._v(_vm._s(_vm.text))]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }