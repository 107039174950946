var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "HqDrawer",
    { ref: "drawer", attrs: { title: "编辑收款凭证", size: "60%" } },
    [
      _c(
        "el-row",
        {
          staticStyle: {
            width: "94%",
            margin: "0 auto",
            "padding-top": "12px"
          },
          attrs: { gutter: 24 }
        },
        [
          _c(
            "el-form",
            {
              ref: "editForm",
              attrs: { model: _vm.editForm, rules: _vm.editRules }
            },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "paymentMethod" } },
                    [
                      _c("v-autocomplete", {
                        staticClass: "normal-select",
                        attrs: {
                          items: _vm.collectTypeList,
                          "menu-props": { bottom: true, offsetY: true },
                          label: "收款方式",
                          outlined: "",
                          "item-text": "name",
                          "item-value": "id",
                          dense: true,
                          height: 32,
                          clearable: true,
                          "clear-icon": "$clear",
                          "hide-details": "auto",
                          "no-data-text": "暂无数据"
                        },
                        on: { change: _vm.typeChange },
                        model: {
                          value: _vm.editForm.paymentMethod,
                          callback: function($$v) {
                            _vm.$set(_vm.editForm, "paymentMethod", $$v)
                          },
                          expression: "editForm.paymentMethod"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.editForm.paymentMethod == "8"
                ? [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "paymentPlatform" } },
                          [
                            _c("v-autocomplete", {
                              staticClass: "normal-select",
                              attrs: {
                                items: _vm.accountList,
                                "menu-props": { bottom: true, offsetY: true },
                                label: "银行账户",
                                outlined: "",
                                "item-text": "accountName",
                                "item-value": "id",
                                dense: true,
                                height: 32,
                                clearable: true,
                                "clear-icon": "$clear",
                                "hide-details": "auto",
                                "no-data-text": "暂无数据"
                              },
                              on: { change: _vm.selectBank },
                              model: {
                                value: _vm.editForm.paymentPlatform,
                                callback: function($$v) {
                                  _vm.$set(_vm.editForm, "paymentPlatform", $$v)
                                },
                                expression: "editForm.paymentPlatform"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("el-col", {
                      staticStyle: { height: "54px" },
                      attrs: { span: 8 }
                    }),
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c("div", [
                            _vm._v("流水号"),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*")
                            ])
                          ]),
                          _c(
                            "el-form-item",
                            [
                              _vm.isBank && _vm.selectNcVoucher == 1
                                ? _c("v-autocomplete", {
                                    staticStyle: {
                                      width: "120px",
                                      display: "inline-block"
                                    },
                                    attrs: {
                                      items: [
                                        { name: "搜索选择", id: true },
                                        { name: "手动输入", id: false }
                                      ],
                                      "menu-props": {
                                        bottom: true,
                                        offsetY: true
                                      },
                                      "item-text": "name",
                                      "item-value": "id",
                                      outlined: "",
                                      dense: true,
                                      height: 32,
                                      "clear-icon": "$clear",
                                      "hide-details": "auto",
                                      "no-data-text": "暂无数据"
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.editForm.serialNumber = ""
                                      }
                                    },
                                    model: {
                                      value: _vm.isSearch,
                                      callback: function($$v) {
                                        _vm.isSearch = $$v
                                      },
                                      expression: "isSearch"
                                    }
                                  })
                                : _vm._e(),
                              _c(
                                "v-text-field",
                                {
                                  ref: "serialNumber",
                                  staticStyle: {
                                    display: "inline-block",
                                    width: "400px"
                                  },
                                  attrs: {
                                    autocomplete: "off",
                                    label: "银行流水号",
                                    "hide-details": "auto",
                                    outlined: "",
                                    readonly:
                                      _vm.isSearch &&
                                      _vm.isBank &&
                                      _vm.selectNcVoucher == 1,
                                    dense: true,
                                    height: 32
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.validateThird(
                                        _vm.editForm.paymentMethod
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.editForm.serialNumber,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.editForm,
                                        "serialNumber",
                                        $$v
                                      )
                                    },
                                    expression: "editForm.serialNumber"
                                  }
                                },
                                [
                                  _vm.isSearch &&
                                  _vm.isBank &&
                                  _vm.selectNcVoucher == 1
                                    ? _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            slot: "append",
                                            color: "red"
                                          },
                                          slot: "append"
                                        },
                                        [_vm._v(" search ")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm.isBank &&
                              _vm.editForm.serialNumber &&
                              _vm.isSearch
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "red",
                                        "font-size": "12px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "银行流水可用金额" +
                                          _vm._s(_vm.availablemoney)
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "money" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                label: "收款金额（元）",
                                "hide-details": "auto",
                                outlined: "",
                                dense: true,
                                height: 32,
                                clearable: true
                              },
                              model: {
                                value: _vm.editForm.money,
                                callback: function($$v) {
                                  _vm.$set(_vm.editForm, "money", $$v)
                                },
                                expression: "editForm.money"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm.editForm.paymentMethod == "7"
                ? _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "paymentPlatform" } },
                        [
                          _c("v-autocomplete", {
                            staticClass: "normal-select",
                            attrs: {
                              items: _vm.loanPlatformList,
                              "menu-props": { bottom: true, offsetY: true },
                              label: "贷款平台",
                              outlined: "",
                              "item-text": "loanPlatformName",
                              "item-value": "id",
                              dense: true,
                              height: 32,
                              clearable: true,
                              "clear-icon": "$clear",
                              "hide-details": "auto",
                              "no-data-text": "暂无数据"
                            },
                            model: {
                              value: _vm.editForm.paymentPlatform,
                              callback: function($$v) {
                                _vm.$set(_vm.editForm, "paymentPlatform", $$v)
                              },
                              expression: "editForm.paymentPlatform"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("el-form-item", { attrs: { prop: "photoUrl" } }, [
                    _c("span", [_vm._v("凭证图片")]),
                    _vm.editForm.photoUrl
                      ? _c("div", { staticClass: "img-box" }, [
                          _c("img", {
                            staticStyle: { "object-fit": "contain" },
                            attrs: { src: _vm.editForm.photoUrl, alt: "" }
                          }),
                          _c("i", {
                            staticClass: "el-icon-circle-close",
                            staticStyle: { color: "#999" },
                            on: { click: _vm.imgdelete }
                          })
                        ])
                      : _c(
                          "div",
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.editForm.file,
                                  expression: "editForm.file"
                                }
                              ],
                              staticStyle: { display: "none" },
                              domProps: { value: _vm.editForm.file },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.editForm,
                                    "file",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _c(
                              "el-button",
                              {
                                staticClass: "upload",
                                attrs: { type: "primary", size: "small" }
                              },
                              [_vm._v("选择凭证图片")]
                            ),
                            _c("input", {
                              ref: "file",
                              staticClass: "change",
                              staticStyle: {
                                left: "0",
                                opacity: "0",
                                position: "absolute",
                                width: "100px"
                              },
                              attrs: { type: "file" },
                              on: {
                                change: function($event) {
                                  return _vm.getFile()
                                }
                              }
                            }),
                            _vm.imageUrl
                              ? _c("div", { staticClass: "img-box" }, [
                                  _c("img", {
                                    staticStyle: { "object-fit": "contain" },
                                    attrs: { src: _vm.imageUrl, alt: "" }
                                  })
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                  ])
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editForm.paymentMethod == "7",
                      expression: "editForm.paymentMethod == '7'"
                    }
                  ]
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "money" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "贷款金额（元）",
                              "hide-details": "auto",
                              outlined: "",
                              dense: true,
                              height: 32,
                              clearable: true
                            },
                            model: {
                              value: _vm.editForm.money,
                              callback: function($$v) {
                                _vm.$set(_vm.editForm, "money", $$v)
                              },
                              expression: "editForm.money"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop:
                              _vm.editForm.paymentMethod == "7"
                                ? "installmentNumber"
                                : "installmentNumber2"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "期数",
                              "hide-details": "auto",
                              outlined: "",
                              dense: true,
                              height: 32,
                              clearable: true
                            },
                            model: {
                              value: _vm.editForm.installmentNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.editForm, "installmentNumber", $$v)
                              },
                              expression: "editForm.installmentNumber"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop:
                              _vm.editForm.paymentMethod == "7"
                                ? "serialNumber"
                                : "serialNumber2"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "借据号",
                              "hide-details": "auto",
                              outlined: "",
                              dense: true,
                              height: 32,
                              clearable: true
                            },
                            model: {
                              value: _vm.editForm.serialNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.editForm, "serialNumber", $$v)
                              },
                              expression: "editForm.serialNumber"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop:
                              _vm.editForm.paymentMethod == "7"
                                ? "companyDiscountRate"
                                : "companyDiscountRate2"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "贴息率（%）",
                              "hide-details": "auto",
                              outlined: "",
                              dense: true,
                              height: 32,
                              clearable: true
                            },
                            model: {
                              value: _vm.editForm.companyDiscountRate,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.editForm,
                                  "companyDiscountRate",
                                  $$v
                                )
                              },
                              expression: "editForm.companyDiscountRate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop:
                              _vm.editForm.paymentMethod == "7"
                                ? "companyDiscountMoney"
                                : "companyDiscountMoney2"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "贴息（元）",
                              "hide-details": "auto",
                              outlined: "",
                              dense: true,
                              height: 32,
                              clearable: true
                            },
                            model: {
                              value: _vm.editForm.companyDiscountMoney,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.editForm,
                                  "companyDiscountMoney",
                                  $$v
                                )
                              },
                              expression: "editForm.companyDiscountMoney"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop:
                              _vm.editForm.paymentMethod == "7"
                                ? "guaranteeMoney"
                                : "guaranteeMoney2"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "保证金（元）",
                              "hide-details": "auto",
                              outlined: "",
                              dense: true,
                              height: 32,
                              clearable: true
                            },
                            model: {
                              value: _vm.editForm.guaranteeMoney,
                              callback: function($$v) {
                                _vm.$set(_vm.editForm, "guaranteeMoney", $$v)
                              },
                              expression: "editForm.guaranteeMoney"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop:
                              _vm.editForm.paymentMethod == "7"
                                ? "loanReceivedMoney"
                                : "loanReceivedMoney2"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              autocomplete: "off",
                              label: "到账金额(元)",
                              "hide-details": "auto",
                              outlined: "",
                              dense: true,
                              height: 32,
                              clearable: true
                            },
                            model: {
                              value: _vm.editForm.loanReceivedMoney,
                              callback: function($$v) {
                                _vm.$set(_vm.editForm, "loanReceivedMoney", $$v)
                              },
                              expression: "editForm.loanReceivedMoney"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop:
                              _vm.editForm.paymentMethod == "7"
                                ? "loanTime"
                                : "loanTime2"
                          }
                        },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "menu",
                              attrs: {
                                "close-on-content-click": false,
                                "return-value": _vm.editForm.loanTime,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "auto"
                              },
                              on: {
                                "update:returnValue": function($event) {
                                  return _vm.$set(
                                    _vm.editForm,
                                    "loanTime",
                                    $event
                                  )
                                },
                                "update:return-value": function($event) {
                                  return _vm.$set(
                                    _vm.editForm,
                                    "loanTime",
                                    $event
                                  )
                                }
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                autocomplete: "off",
                                                label: "放款日期",
                                                "hide-details": "auto",
                                                outlined: "",
                                                dense: true,
                                                height: 32,
                                                clearable: true
                                              },
                                              model: {
                                                value: _vm.editForm.loanTime,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.editForm,
                                                    "loanTime",
                                                    $$v
                                                  )
                                                },
                                                expression: "editForm.loanTime"
                                              }
                                            },
                                            "v-text-field",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.menu,
                                callback: function($$v) {
                                  _vm.menu = $$v
                                },
                                expression: "menu"
                              }
                            },
                            [
                              _c(
                                "v-date-picker",
                                {
                                  attrs: { "no-title": "", locale: "zh-cn" },
                                  model: {
                                    value: _vm.editForm.loanTime,
                                    callback: function($$v) {
                                      _vm.$set(_vm.editForm, "loanTime", $$v)
                                    },
                                    expression: "editForm.loanTime"
                                  }
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function($event) {
                                          _vm.menu = false
                                        }
                                      }
                                    },
                                    [_vm._v("取消")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$refs.menu.save(
                                            _vm.editForm.loanTime
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" 确认 ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.editForm.paymentMethod != 8 && _vm.editForm.paymentMethod != 7
                ? _c(
                    "div",
                    [
                      _vm.payType == 5 &&
                      (_vm.selectNcVoucher == 1 || _vm.relateTyStatement == 1)
                        ? _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "v-text-field",
                                    {
                                      ref: "serialNumber",
                                      attrs: {
                                        autocomplete: "off",
                                        label: "第三方订单号",
                                        "hide-details": "auto",
                                        outlined: "",
                                        readonly: "",
                                        dense: true,
                                        height: 32
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.validateThird(
                                            _vm.editForm.paymentMethod
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.editForm.serialNumber,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.editForm,
                                            "serialNumber",
                                            $$v
                                          )
                                        },
                                        expression: "editForm.serialNumber"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            slot: "append",
                                            color: "red"
                                          },
                                          slot: "append"
                                        },
                                        [_vm._v(" search ")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.payType == 5 &&
                                  (_vm.selectNcVoucher == 1 ||
                                    _vm.relateTyStatement == 1) &&
                                  _vm.editForm.serialNumber
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            color: "red",
                                            "font-size": "12px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "第三方订单可用金额" +
                                              _vm._s(_vm.availablemoney)
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "money" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "收款金额（元）",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: true,
                                  height: 32,
                                  clearable: true
                                },
                                model: {
                                  value: _vm.editForm.money,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editForm, "money", $$v)
                                  },
                                  expression: "editForm.money"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.payType != 5 ||
                      (_vm.payType == 5 &&
                        _vm.selectNcVoucher != 1 &&
                        _vm.relateTyStatement != 1)
                        ? _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "serialNumber" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label:
                                        _vm.editForm.paymentMethod == 20
                                          ? "特批单号"
                                          : "流水号",
                                      "hide-details": "auto",
                                      outlined: "",
                                      dense: true,
                                      height: 32,
                                      clearable: true
                                    },
                                    model: {
                                      value: _vm.editForm.serialNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editForm,
                                          "serialNumber",
                                          $$v
                                        )
                                      },
                                      expression: "editForm.serialNumber"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "memo-form", attrs: { prop: "remark" } },
                    [
                      _c("v-textarea", {
                        attrs: {
                          outlined: "",
                          height: 80,
                          name: "input-7-4",
                          label: "凭证备注",
                          value: "",
                          placeholder: "请输入，最多500字"
                        },
                        model: {
                          value: _vm.editForm.remark,
                          callback: function($$v) {
                            _vm.$set(_vm.editForm, "remark", $$v)
                          },
                          expression: "editForm.remark"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.cancel } },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary", loading: _vm.loadding },
              on: { click: _vm.save }
            },
            [_vm._v("提交")]
          )
        ],
        1
      ),
      _c("third-party", {
        ref: "third",
        attrs: { modal: false },
        on: { thirdSelect: _vm.thirdSelect }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }