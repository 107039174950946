<!--
 * @Description: 编辑收款凭证
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-08-07 16:04:11
 * @LastEditors: Seven
 * @LastEditTime: 2022-07-14 11:35:44
-->
<template>
  <HqDrawer
    title="编辑收款凭证"
    size="60%"
    ref="drawer"
  >
    <el-row :gutter="24" style="width: 94%;margin: 0 auto;padding-top: 12px">
      <el-form ref="editForm" :model="editForm" :rules="editRules">
        <el-col :span="8">
          <el-form-item prop="paymentMethod">
            <v-autocomplete
              v-model="editForm.paymentMethod"
              :items="collectTypeList"
              :menu-props="{ bottom: true, offsetY: true }"
              label="收款方式"
              outlined
              item-text="name"
              item-value="id"
              :dense="true"
              :height="32"
              :clearable="true"
              clear-icon="$clear"
              hide-details="auto"
              no-data-text="暂无数据"
              class="normal-select"
              @change="typeChange"
            ></v-autocomplete>
          </el-form-item>
        </el-col>
        <!-- 收款方式银行 -->
        <template v-if="editForm.paymentMethod == '8'">
          <el-col :span="8">
            <el-form-item prop="paymentPlatform">
              <v-autocomplete
                v-model="editForm.paymentPlatform"
                :items="accountList"
                :menu-props="{ bottom: true, offsetY: true }"
                label="银行账户"
                outlined
                item-text="accountName"
                item-value="id"
                :dense="true"
                :height="32"
                :clearable="true"
                clear-icon="$clear"
                hide-details="auto"
                no-data-text="暂无数据"
                class="normal-select"
                @change="selectBank"
              ></v-autocomplete>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="height: 54px"></el-col>
          <template>
            <el-col :span="24">
            <div>流水号<span style="color: red">*</span></div>
              <!-- 凭证流水号 -->
              <el-form-item>
                <v-autocomplete
                  v-if="isBank && selectNcVoucher == 1"
                  style="width: 120px;display: inline-block"
                  v-model="isSearch"
                  :items="[{name: '搜索选择', id: true}, {name: '手动输入', id: false}]"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="name"
                  item-value="id"
                  outlined
                  :dense="true"
                  :height="32"
                  clear-icon="$clear"
                  hide-details="auto"
                  no-data-text="暂无数据"
                  @change="editForm.serialNumber = ''"
                ></v-autocomplete>
                <v-text-field
                  style="display: inline-block;width: 400px"
                  ref="serialNumber"
                  autocomplete = "off"
                  v-model="editForm.serialNumber"
                  label="银行流水号"
                  hide-details="auto"
                  outlined
                  :readonly="isSearch && isBank && selectNcVoucher == 1"
                  :dense="true"
                  :height="32"
                  @click.native="validateThird(editForm.paymentMethod)"
                >
                  <v-icon
                    slot="append"
                    color="red"
                    v-if="isSearch && isBank && selectNcVoucher == 1"
                  >
                    search
                  </v-icon>
                </v-text-field>
                <div v-if="isBank && editForm.serialNumber && isSearch" style="color: red;font-size: 12px">银行流水可用金额{{ availablemoney }}</div>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="16" style="height: 62px">
              <div class="pz-btn" @click="() => {isSearch = !isSearch;editForm.serialNumber=''}">切换为"手动填写"银行流水号</div>
            </el-col> -->
          </template>
          <!-- <template v-else>
            <el-col :span="8">
              <el-form-item prop="serialNumber">
                <v-text-field
                  v-model="editForm.serialNumber"
                  label="流水号"
                  hide-details="auto"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                ></v-text-field>
              </el-form-item>
            </el-col>
            <el-col :span="16" style="height: 62px" v-if="isBank">
              <div class="pz-btn" @click="() => {isSearch = !isSearch;editForm.serialNumber=''}">切换为"搜索选择"银行流水号</div>
            </el-col>
          </template> -->
          <el-col :span="8">
            <el-form-item prop="money">
              <v-text-field
                v-model="editForm.money"
                label="收款金额（元）"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
              ></v-text-field>
            </el-form-item>
          </el-col>
        </template>
        <!-- 收款方式贷款 -->
        <el-col :span="8" v-if="editForm.paymentMethod == '7'">
          <el-form-item prop="paymentPlatform">
            <v-autocomplete
              v-model="editForm.paymentPlatform"
              :items="loanPlatformList"
              :menu-props="{ bottom: true, offsetY: true }"
              label="贷款平台"
              outlined
              item-text="loanPlatformName"
              item-value="id"
              :dense="true"
              :height="32"
              :clearable="true"
              clear-icon="$clear"
              hide-details="auto"
              no-data-text="暂无数据"
              class="normal-select"
            ></v-autocomplete>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="8" style="height:54px"></el-col> -->
        <el-col :span="24">
          <el-form-item prop="photoUrl">
            <span>凭证图片</span>
            <div class="img-box" v-if="editForm.photoUrl">
              <img :src="editForm.photoUrl"  style="object-fit:contain" alt="">
              <i class="el-icon-circle-close" style="color: #999" @click="imgdelete"></i>
            </div>
            <div v-else>
              <input v-model="editForm.file" style="display: none" />
              <el-button class="upload" type="primary" size="small">选择凭证图片</el-button>
              <input class="change" type="file"  ref="file"  style="left: 0;opacity: 0; position: absolute;width: 100px" @change="getFile()">
              <div class="img-box" v-if="imageUrl">
                <img style="object-fit:contain" :src="imageUrl" alt="">
              </div>
          </div>
          </el-form-item>
        </el-col>
        <!-- 收款方式贷款 -->
        <div v-show="editForm.paymentMethod == '7'">
          <el-col :span="8">
            <el-form-item prop="money">
              <v-text-field
                v-model="editForm.money"
                label="贷款金额（元）"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
              ></v-text-field>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :prop="editForm.paymentMethod == '7'?'installmentNumber':'installmentNumber2'">
              <v-text-field
                v-model="editForm.installmentNumber"
                label="期数"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
              ></v-text-field>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :prop="editForm.paymentMethod == '7'?'serialNumber':'serialNumber2'">
              <v-text-field
                v-model="editForm.serialNumber"
                label="借据号"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
              ></v-text-field>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :prop="editForm.paymentMethod == '7'?'companyDiscountRate':'companyDiscountRate2'">
              <v-text-field
                v-model="editForm.companyDiscountRate"
                label="贴息率（%）"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
              ></v-text-field>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :prop="editForm.paymentMethod == '7'?'companyDiscountMoney':'companyDiscountMoney2'">
              <v-text-field
                v-model="editForm.companyDiscountMoney"
                label="贴息（元）"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
              ></v-text-field>
            </el-form-item>
          </el-col>

           <el-col :span="8">
            <el-form-item :prop="editForm.paymentMethod == '7'?'guaranteeMoney':'guaranteeMoney2'">
              <v-text-field
                v-model="editForm.guaranteeMoney"
                label="保证金（元）"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
              ></v-text-field>
            </el-form-item>
          </el-col>

          <el-col :span="8" >
            <el-form-item :prop="editForm.paymentMethod == '7'?'loanReceivedMoney':'loanReceivedMoney2'">
              <!-- 5、到账金额 -->
              <v-text-field
                autocomplete = "off"
                v-model="editForm.loanReceivedMoney"
                label="到账金额(元)"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
                :clearable="true">
              </v-text-field>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :prop="editForm.paymentMethod == '7'?'loanTime':'loanTime2'">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="editForm.loanTime"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    autocomplete = "off"
                    v-model="editForm.loanTime"
                    label="放款日期"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="editForm.loanTime" no-title locale="zh-cn">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">取消</v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(editForm.loanTime)"
                  >
                    确认
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </el-form-item>
          </el-col>
        </div>
        <!-- 收款方式其他 -->
        <div v-if="editForm.paymentMethod != 8 && editForm.paymentMethod != 7">
          <el-col :span="8" v-if="payType == 5 && (selectNcVoucher == 1 || relateTyStatement == 1)">
            <!-- 凭证流水号 -->
            <el-form-item>
              <v-text-field
                ref="serialNumber"
                autocomplete = "off"
                v-model="editForm.serialNumber"
                label="第三方订单号"
                hide-details="auto"
                outlined
                readonly
                :dense="true"
                :height="32"
                @click.native="validateThird(editForm.paymentMethod)"
              >
                <v-icon
                  slot="append"
                  color="red"
                >
                  search
                </v-icon>
              </v-text-field>
              <div v-if="payType == 5 && (selectNcVoucher == 1 || relateTyStatement == 1) && editForm.serialNumber" style="color: red;font-size: 12px">第三方订单可用金额{{ availablemoney }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="money">
              <v-text-field
                v-model="editForm.money"
                label="收款金额（元）"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
              ></v-text-field>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="payType != 5 || (payType == 5 && selectNcVoucher != 1 && relateTyStatement != 1)">
            <el-form-item prop="serialNumber">
              <v-text-field
                v-model="editForm.serialNumber"
                :label="editForm.paymentMethod == 20?'特批单号':'流水号'"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
              ></v-text-field>
            </el-form-item>
          </el-col>
        </div>
        <el-col :span="18">
          <!-- 凭证备注 -->
          <el-form-item class="memo-form" prop="remark">
            <v-textarea
              v-model="editForm.remark"
              outlined
              :height="80"
              name="input-7-4"
              label="凭证备注"
              value=""
              placeholder="请输入，最多500字"
            ></v-textarea>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <div slot="footer">
      <el-button size="small" @click="cancel">取消</el-button>
      <el-button size="small" type="primary" @click="save" :loading="loadding">提交</el-button>
    </div>
    <third-party ref="third" :modal="false" @thirdSelect="thirdSelect" />
  </HqDrawer>
</template>
<script>
import axios from '@/utils/request';
import { formatTimesFileName } from '@/utils/timeDate'
import thirdParty from "../../management/collection/third-party"
export default {
  components: { thirdParty },
  props: {
    collectTypeList: {
      type: Array
    },
    accountList: {
      type: Array
    },
    loanPlatformList: {
      type: Array,
    },
    method: {
      type: Function,
    },
    // payPrice: {
    //   default: 0
    // }
  },
  data() {
    const validateMoney = (rule, value, callback) => {
      if (!value) {
        if(this.editForm.type == 17) {
          callback(new Error('请输入贷款金额'))
        } else {
          callback(new Error('请输入收款金额'))
        }
      } else {
        if(/\.[\d.]{3,}$/.test(value)) {
          callback(new Error('输入金额仅支持两位小数'))
        }
        if( isNaN(value) ){
           callback(new Error('输入金额异常,请重新输入'))
        } 
        if(Number(value) * 100 > Number(this.payPrice)*100 + Number(this.oldPrice) * 100) {
          callback(new Error("输入金额不能大于商品价格"))
        }
        if(this.payType == 5 && (this.selectNcVoucher == 1 || this.relateTyStatement == 1)) {
          if(this.oldSerialNumber == this.editForm.serialNumber && Number(value) > Number(this.availablemoney) + Number(this.oldMoney)) {
            callback(new Error("第三方凭证收款金额不可超出第三方订单可用金额"))
          } else if(this.oldSerialNumber != this.editForm.serialNumber && Number(value) > Number(this.availablemoney)) {
            callback(new Error("第三方凭证收款金额不可超出第三方订单可用金额"))
          }
        }

        if(this.editForm.paymentMethod == 8 && this.isBank) {
          if(Number(value) > Number(this.availablemoney) + Number(this.oldMoney)) {
            callback(new Error("银行收款金额不可超出银行可用金额"))
          }
        }
        callback()
      }
    };
    const validateRate = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入贴息率'))
      } else {
        if(/\.[\d.]{3,}$/.test(value)) {
          callback(new Error('输入贴息率仅支持两位小数'))
        }
        if( isNaN(value) ){
           callback(new Error('输入贴息率异常,请重新输入'))
        } 
        callback()
      }
    };
    const validateDiscountMoney = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入贴息金额'))
      } else {
        if(/\.[\d.]{3,}$/.test(value)) {
          callback(new Error('输入贴息金额仅支持两位小数'))
        }
        if( isNaN(value) ){
           callback(new Error('输入贴息金额异常,请重新输入'))
        } 
        callback()
      }
    };
    const validateInstallmentNumber = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入到贷款期数'))
      } else {
        if(/[^0-9]/.test(value)) {
          callback(new Error('输入期数仅支持整数'))
        }
        if( value <= 0 || value >= 1000) {
           callback(new Error('期数的值仅支持（1~999）'))
        } 
        callback()
      }
    };
    const validateLoanReceivedMoney = async (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入到账金额'))
      } else {
        if(/\.[\d.]{3,}$/.test(value)) {
          callback(new Error('输入金额仅支持两位小数'))
        }
        if( isNaN(value) ){
           callback(new Error('输入金额异常,请重新输入'))
        } 
        callback()
      }
    };
    const validateguaranteeMoney = async (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入保证金额'))
      } else {
        if( isNaN(value) ){
           callback(new Error('输入金额异常,请重新输入'))
        } 
        callback()
      }
    };
    const validateRemark = (rule, value, callback) => {
      if (value && value.length > 500) {
        callback(new Error('凭证备注长度不能大于500'))
      } else {
        callback()
      }
    };
    // 凭证流水
    const validateSerialNumber = async (rule, value, callback) => {
      if(!value) callback(new Error('请输入流水号'))
      if(this.oldSerialNumber == value) {
        callback()
      } else {
        if(value.length > 50) return callback(new Error('流水号长度不能大于50'))
        const { data } = await this.$fetch("checkSerialNumber", { serialNumber: value })
        
        if(data != 0) {
          callback(new Error(`${value}已经存在于数据库中`))
        } else {
          callback()
        }
      }
    };
    return {
      editForm: {
        // type: "",
        photoUrl: "",
        paymentMethod: "",
        paymentPlatform: "",
        money: "",
        serialNumber: "",
        loanReceivedMoney: "",
        installmentNumber: null,
        guaranteeMoney: ""
      },
      imgShow: true,
      imageUrl: "",
      editRules: {
        paymentMethod: [
          {required: true, message: "请选择收款方式"}
        ],
        paymentPlatform: [
          {required: true, message: "请选择银行账号"}
        ],
        money: [
          {required: true, validator: validateMoney}
        ],
        serialNumber: [
          {required: true,validator: validateSerialNumber}
        ],
        serialNumber2: [],
        photoUrl: [
          {required: true, message: "请上传凭证截图"}
        ],
        companyDiscountRate: [
          {required: true, validator: validateRate}
        ],
        companyDiscountRate2: [],
        companyDiscountMoney: [
          {required: true, validator: validateDiscountMoney}
        ],
        companyDiscountMoney2: [],
        loanReceivedMoney: [
          {required: true, validator: validateLoanReceivedMoney}
        ],
        loanReceivedMoney2: [],
        loanTime: [
          {required: true, message: "请选择放贷日期", trigger: 'blur'}
        ],
        loanTime2: [],
        remark: [
          {required: true, validator: validateRemark}
        ],
        installmentNumber: [
          {required: true, validator: validateInstallmentNumber, type: 'number', trigger: 'blur'}
        ],
        guaranteeMoney: [
          {required: true, validator: validateguaranteeMoney}
        ],
        guaranteeMoney2: [],
      },
      menu: false,
      oldSerialNumber: "",
      // 第三方可用金额
      availablemoney: "",
      payType: "",
      // nc
      selectNcVoucher: 1,
      // ty
      relateTyStatement: 1,
      oldPrice: "",
      oldMoney: "",
      loadding: false,
      // 是否展示银行流水号
      isBank: false,
      payPrice: 0,
      bankAccountPk: "",
      isSearch: true,
    }
  },
  methods: {
    init(row, payPrice) {
      this.payPrice = payPrice
      this.$refs.drawer.init()
      this.$fetch("third_GetqueryPayVoucherEntityListByIdList", {processInstanceIdList: [row.processInstanceId]}).then(res => {
        if(res.code === 200) {
          this.editForm = res.data[0]
          this.oldSerialNumber = res.data[0].serialNumber
          this.editForm.type = res.data[0].paymentMethod
          this.editForm.paymentMethod = this.editForm.paymentMethod
          this.oldPrice = res.data[0].money
          if(this.editForm.loanTime) {
            this.editForm.loanTime = this.$options.filters.formatDate(this.editForm.loanTime, 2)
          }
          this.collectTypeList.map(item => {
            if(item.id == res.data[0].paymentMethod) {
              this.editForm.ncCode = item.ncCode
              this.editForm.settlePayType = item.payType || ""
              this.selectNcVoucher = item.selectNcVoucher
              this.relateTyStatement = item.relateTyStatement
              this.editForm.selectNcVoucher = item.selectNcVoucher
            }
            if(item.id == res.data[0].paymentMethod && item.payType == 5) {
              this.payType = 5
              let tableData = []
              if(res.data[0].statementId) {
                // ty
                this.$fetch("getStatementFindAll", {
                  type: 2, 
                  exceptionStatus: 0, 
                  extOrderNo: res.data[0].serialNumber,
                  settlementId: item.id
                }).then(result => {
                  this.availablemoney = result.data[0].moneyAvailable
                  this.oldMoney = res.data[0].money
                  this.editForm.statementId = res.data[0].statementId
                })

              } else {
                // nc
                this.$fetch("GetOneThirdpartyOrderDataServlet", {platformordernum: res.data[0].serialNumber, clearingformcode: item.ncCode}).then(result => {
                  tableData = result.data || []
                  this.availablemoney = tableData.availablemoney
                  this.oldMoney = res.data[0].money
                })
              }
            }
          })
          
          if(res.data[0].paymentMethod == 8 && res.data[0].bankAccountPk && !!res.data[0].statementId) {
            this.$fetch("getPOSMchidServlet", { accnum: res.data[0].bankAccountPk}).then(result => {
              this.isBank = result.mchid?true : false
              this.bankAccountPk = res.data[0].bankAccountPk
              this.$fetch("GetOneThirdpartyOrderDataServlet", {platformordernum: res.data[0].serialNumber, clearingformcode: 18}).then(result1 => {
                this.availablemoney = result1.data.availablemoney || 0
                this.oldMoney = res.data[0].money
              })
            })
          }
          this.$nextTick(() => {
            this.$refs.editForm.clearValidate()
            this.$forceUpdate()
          });
        }
      })
    },
    validateThird() {
      if(this.editForm.paymentMethod == 8 && (!this.isSearch || !this.isBank || this.selectNcVoucher != 1)) return false
      this.modalShow = false
      let thirdType
      if(this.payType == 5) {
        thirdType = this.relateTyStatement?'ty':this.selectNcVoucher?'nc':''
      }
      this.collectTypeList.map(item => {
        if(item.id == this.editForm.paymentMethod) {
          this.$refs.third.init({clearingformcode: item.ncCodeRef || item.ncCode, paymentMethod: this.editForm.paymentMethod, bankAccountPk: this.bankAccountPk, thirdType, oldSerialNumber: this.oldSerialNumber, id: item.id})
        }
      })
    },
    thirdSelect(id, availablemoney, index, statementId) {
      // 编辑前的流水号和编辑后的流水号是一样的
      if(this.oldSerialNumber == id) {
        this.availablemoney = availablemoney + this.oldPrice
      } else {
        this.availablemoney = availablemoney
      }
      this.editForm.serialNumber = id
      this.editForm.statementId = statementId
      this.editForm.money = ""
    },
    imgdelete() {
      this.editForm.photoUrl = ''
      this.imageUrl = ''
    },
    typeChange(value) {
      console.log('收款方式',value);
      this.$refs.editForm.clearValidate()
      this.collectTypeList.map(item => {
        if(item.id == value) {
          this.payType = item.payType
          this.selectNcVoucher = item.selectNcVoucher
          this.editForm.ncCode = item.ncCode
          this.editForm.settlePayType = item.payType || ""
          this.relateTyStatement = item.relateTyStatement
          this.editForm.selectNcVoucher = item.selectNcVoucher
        }
      })
      this.editForm.paymentPlatform = ""
      this.editForm.money = ""
      this.editForm.serialNumber = ""
      this.editForm.companyDiscountRate = ""
      this.editForm.companyDiscountMoney = ""
      this.editForm.loanReceivedMoney = ""
      this.editForm.loanTime = ""
      this.editForm.installmentNumber = ""
      this.editForm.remark = ""
      this.editForm.photoUrl = ""
      this.editForm.file = ""
      this.imageUrl = ""
      this.$forceUpdate()
    },
    selectBank(value) {
      if(this.selectNcVoucher != 1) return false
      this.accountList.map(async item => {
        if(item.id == value) { // item.bankAccountPk
          const res = await this.$fetch("getPOSMchidServlet", { accnum: item.bankAccountPk})
          this.isBank = res.mchid?true : false
          this.bankAccountPk = item.bankAccountPk
        }
      })
    },
    async getFile () {
      const file = this.$refs.file.files[0]
      // this.editForm.file = file
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          this.imageUrl = img.src;
        };
      }

      //1.先获取凭证
      this.$fetch("ossSigniture", {code: 'tianyi'}).then( (res={})=>{
        let data = res.data;
         let formData = new FormData()
         const random = this.getRandomNum(10000, 99999)
         const len = file.name.split('.')
         const time = formatTimesFileName(new Date()).replace(/-/g, '')
        //  修改上传的文件名
         const renameFile = new File([file], `${time}${random}.${len[len.length-1]}`, {type: file.type})
         //  formData.append("name", file.name);
        formData.append('name', `${time}${random}.${len[len.length-1]}`)
          formData.append("name", file.name);
         // "\${filename}"固定写法，不是要取变量
         formData.append("key",`${data.dir}\${filename}` )
         formData.append("policy", data.policy)
         formData.append("OSSAccessKeyId", data.accessid)
         formData.append("expire", data.expire) 
         formData.append("host",  data.host) 
         formData.append("success_action_status", 200) 
         formData.append("signature", data.signature) 
          //  formData.append("file", file);
          formData.append("file", renameFile);

         //提交
         axios.post(data.host, formData, {
           headers: {
             "Content-Type": "application/x-www-form-urlencoded"
           }
         }).then( ()=>{
           const host = "https://hq-tianyi.hqjy.com";
           // let photoUrl = `${host}/${this.imgUpload.dir}${this.imgUpload.name}`
             const photoUrl = `${host}/${data.dir}${time}${random}.${len[len.length - 1]}`
            //  let photoUrl = `${host}/${data.dir}${file.name}`
           this.editForm.photoUrl = process.env.VUE_APP_BASE_URL + "/mz_proxy?url="+encodeURI(photoUrl);
         }).catch( error=>{
           this.$message.error(error.msg)
          //  alert("error: "+JSON.stringify(error))
         } );
         
         
      })
      //2.根据凭证提交


     
      // this.$fetch("third_doUpload", formData).then(res => {
      //   if(res.code === 200) {
      //     this.editForm.photoUrl = res.data.url
      //   }
      // })
    },
    getRandomNum(Min,Max) {
      let Range = Max - Min;
      let Rand = Math.random();
      return(Min + Math.round(Rand * Range));
    },

    // 提交
    save() {
      this.$refs.editForm.validate(async (valid) => {
        if(valid) {
          let params = {
            orderId: this.editForm.orderId,
            startUserId: this.$store.state.user.loginUser.tyUserId,
            dealUserId: this.editForm.dealUserId,
            wxPayVoucherEntityList: [
              this.editForm
            ]
          }
          this.loadding = true
          this.$fetch("third_doPayVoucher", params).then(data => {
            if(data.code === 200) {
              this.$message.success(data.msg)
              this.method()
            } else {
              this.$message.error(data.msg)
            }
            this.loadding = false
            this.cancel()
          }).catch((error) => {
            this.$message.error(error.msg)
            this.loadding = false
          })
        }
      })
    },
    // 取消
    cancel() {
      this.$refs.drawer.close()
    }
  }
}
</script>
<style lang="scss" scoped>
  .img-box {
    width: 244px;
    height: 136px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    i {
      position: absolute;
      top: 6px;
      right: 6px;
      font-size: 18px;
      color: #fff;
      cursor: pointer;
    }
  }

  .pz-btn {
    line-height: 28px;
    display: inline-block;
    padding: 0 8px;
    border: 1px solid #3a8ee6;
    background: #3a8ee6;
    color: #fff;
    cursor: pointer;
  }
</style>
