<!--
 * @Description: 收款详情
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-08-07 14:22:03
 * @LastEditors: Seven
 * @LastEditTime: 2022-09-23 09:45:00
-->
<template>
  <div class="collection-detail">
    <div class="page-color">
      <div class="page-width">
        <h2>订单信息</h2>
        <el-row :gutter="24">
          <el-col :span="4">
            <v-text-field
              v-model="orderDetails.orderNo"
              label="订单编号"
              hide-details="auto"
              disabled
              outlined
              :dense="true"
              :height="32"
            ></v-text-field>
          </el-col>
          <el-col :span="4">
            <v-text-field
              v-model="orderDetails.goodsName"
              label="报名商品"
              hide-details="auto"
              disabled
              outlined
              :dense="true"
              :height="32"
            ></v-text-field>
          </el-col>
          <el-col :span="4">
            <v-text-field
              v-model="orderDetails.userName"
              label="学员姓名"
              hide-details="auto"
              disabled
              outlined
              :dense="true"
              :height="32"
            ></v-text-field>
          </el-col>
          <el-col :span="4">
            <v-text-field
              v-model="orderDetails.userPhone"
              label="学习账号"
              hide-details="userPhone"
              disabled
              outlined
              :dense="true"
              :height="32"
            ></v-text-field>
          </el-col>
          <el-col :span="4">
            <v-text-field
              v-model="orderDetails.payPrice"
              label="应收金额"
              hide-details="auto"
              disabled
              outlined
              :dense="true"
              :height="32"
            ></v-text-field>
          </el-col>
        </el-row>
      </div>
    </div>
    <div style="margin-top: 12px" class="page-color">
      <div class="page-width">
        <h2>收款信息</h2>
        <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            border
            stripe
            size="mini"
            element-loading-text="拼命加载中"
            :row-style="{ height: '0px' }"
            :cell-style="{ padding: '0px' }"
          >
            <el-table-column :show-overflow-tooltip="true"
              label="凭证照片"
              prop="deptName"
              min-width="100"
              
            >
              <template slot-scope="scope">
                <div class="img-box">
                  <el-image 
                    style="width: 96px; height: 54px"
                    :src="scope.row.photoUrl" 
                    :preview-src-list="[scope.row.photoUrl]">
                  </el-image>
                </div>
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true"
              label="支付系统流水"
              prop="serialNumber"
              width="180"  
            >
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true"
              label="收款方式信息"
              prop="paymentMethodName"
              width="200"  
            >
              <template slot-scope="scope">
                <div>
                  <span>{{ scope.row.paymentMethodName }}</span>
                  <div v-if="scope.row.accountName">{{ scope.row.accountName }}</div>
                  <div><span>{{ scope.row.paymentPlatformName }}</span> <span style="margin-left: 10px" v-if="scope.row.installmentNumber">{{ scope.row.installmentNumber + '期' }}</span></div>
                  <div><span v-if="scope.row.companyDiscountRate">{{ '贴息率：' + scope.row.companyDiscountRate }}</span><span style="margin-left: 10px" v-if="scope.row.companyDiscountMoney">{{ '贴息金额：' + scope.row.companyDiscountMoney }}</span></div>
                  <div v-if="scope.row.guaranteeMoney">{{ '保证金：' +scope.row.guaranteeMoney }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true"
              label="收款金额"
              prop="money"
              min-width="100"  
            >
              <template slot-scope="scope">
                <div>
                  <span style="font-size: 14px;font-weight: bold;color: #F54955">{{ scope.row.money + '元' }}</span>
                  <div v-if="scope.row.loanReceivedMoney">{{ '到账：' + scope.row.loanReceivedMoney }}</div>
                  <div v-if="scope.row.loanDate">放款时间：{{ scope.row.loanDate | formatDate(2) }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true"
              label="提交信息"
              prop="deptName"
              min-width="120"
              
            >
              <template slot-scope="scope">
                <div>
                  <span>{{ scope.row.assigneeName }}</span>
                  <p>{{ scope.row.startTime }}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="凭证备注"
              prop="remark"
              width="200"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.remark">
                  {{ scope.row.remark.length > 20? scope.row.remark.slice(0, 20) + '...':scope.row.remark }}
                  <el-button type="text" class="hq-button" v-if="scope.row.remark.length > 20" @click="seeAll(scope.row.remark)">查看全部</el-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true"
              label="当前办理人"
              prop="currentHandlerName"
              width="180"  
            >
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true"
              label="审核状态"
              prop="checkStatus"
              width="80"
              
            >
              <template slot-scope="scope">
                <div :style="{color: scope.row.checkStatus === 1?'#666':scope.row.checkStatus === 2?'#00AB84':'#F54955'}">{{ scope.row.checkStatus | convert(checkList) }}</div>
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true"
              label="操作"
              prop="deptName"
              width="300"
              
              fixed="right"
            >
              <template slot-scope="scope">
                <span v-if="loginUser.dataScope == 1 || loginUser.dataScope == 2 || scope.row.currentHandlerId == loginUser.tyUserId">
                  <el-button v-if="scope.row.activityId === '_6'" size="small" type="primary" @click="passClick(scope.row)">通过</el-button>
                  <el-button v-if="scope.row.activityId === '_6'" size="small" type="error" @click="rejectClick(scope.row)">驳回</el-button>
                  <el-button v-if="scope.row.activityId === '_5'" size="small" type="primary" @click="editClick(scope.row)">编辑</el-button>
                  <el-button v-if="scope.row.isDelete" size="small" type="danger" @click="deleteClick(scope.row)">删除</el-button>
                </span>
              </template>
            </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="go-back">
      <el-button @click="backClick">返回</el-button>
    </div>
    <HqModal :visible="rejectModel" title="提示" @on-cancel="cancel">
      <el-form ref="rejectForm" :model="rejectForm" :rules="rejectRules">
        <el-form-item prop="comment">
          <v-textarea
            v-model="rejectForm.comment"
            outlined
            :height="80"
            name="input-7-4"
            label="驳回原因"
            placeholder="请输入驳回原因"
          ></v-textarea>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="save" type="primary">确认</el-button>
      </div>
    </HqModal>
    <!-- 编辑 -->
    <EditComponent 
      :loanPlatformList="loanPlatformList" 
      :collectTypeList="collectTypeList" 
      :accountList="accountList" 
      :method="getqueryPayVoucherDetailsByProcessInstanceId" 
      ref="edit" 
    />

    <el-dialog
      title="凭证备注"
      :visible.sync="dialogVisible"
      center
      width="35%">
      <div style="max-height: 300px;overflow: hidden;overflow-y: auto">{{ text }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import EditComponent from "./edit.vue";
import { getTransactionInfoByOrderId } from "api/customer";
export default {
  name: "collectionDetail",
  components: {
    EditComponent
  },
  data() {
    return {
      tableData: [],
      rejectForm: {
        comment: ""
      },
      rejectRules: {
        comment: [
          {required: true, message: "请输入驳回原因"}
        ]
      },
      rejectModel: false,
      // 订单信息
      orderDetails: {},
      // 收款信息
      collectionInformation: {},
      seeMoreShow: true,
      // 审核状态
      checkList: {
        1: "审核中",
        2: "初审通过",
        3: "初审驳回",
        4: "复审通过",
        5: "复审驳回",
      },
      collectTypeList: [],
      accountList: [],
      loanPlatformList: [],
      // 全部凭证
      allCollectionInformation: [],
      // 编辑时候可以修改的最大金额
      price: 0,
      dialogVisible: false,
      text: ""
    }
  },
  computed: {
    ...mapGetters(["loginUser"])
  },
  mounted() {
    this.getqueryPayVoucherDetailsByProcessInstanceId()
    this.getFindSettlementMethod()
    this.findLoanPlatform()
  },
  methods: {
    seeAll(text) {
      this.text = text
      this.dialogVisible = true
    },
    // 获取收款方式
    getFindSettlementMethod() {
      this.$fetch("third_findSettlementMethod").then(res => {
        if(res.code === 200) {
          this.collectTypeList = res.data
        }
      })
    },
    // 获取成交信息
    getTransactionInfo(orderId){
      const form = { orderId }
      getTransactionInfoByOrderId(form).then( res=>{
        const { code, result } = res
        if( code === 0 ){
          result && this.getFindFundMethod(result.ncDealSchoolId)
        }
      })
    },
    // 获取银行账户
    getFindFundMethod(schoolId) {
      this.$fetch("findFundMethod", {schoolId, type: 0}).then(res => {
        if(res.code === 200) {
          this.accountList = res.data
        }
      })
    },
    findLoanPlatform(){
      this.$fetch("findLoanPlatform").then(res => {
        this.loanPlatformList = res.data
      })
    },
    getqueryPayVoucherDetailsByProcessInstanceId() {
      const processInstanceId = this.$route.query.id
      this.$fetch("third_GetqueryPayVoucherDetailsByProcessInstanceId", { processInstanceId }).then(res => {
        if(res.code === 200) {
          this.seeMoreShow = true
          if(res.data.collectionInformation.processInstanceId)
          this.orderDetails = res.data.orderDetails
          this.collectionInformation = res.data.collectionInformation
          if(!this.collectionInformation.processInstanceId) {
            this.$router.push("/afterSale/collection-list")
          }
          this.getTransactionInfo(this.orderDetails.orderId)
          this.getAllVoucherDetails(this.orderDetails.orderId)
        }
      })
    },
    getAllVoucherDetails(orderId) {
      this.allCollectionInformation = []
      this.$fetch("third_GetqueryPayVoucherDetailsByOrderId", {orderId: orderId}).then(res => {
        if(res.code === 200) {
          this.allCollectionInformation = res.data
          this.tableData = res.data
        }
      })
    },
    // 确认凭证
    passClick(row) {
      this.$hqMessageBox({
        message:
          "确认审核通过？",
        })
        .then(() => {
          this.$fetch("third_GetqueryPayVoucherEntityListByIdList", {processInstanceIdList: [row.processInstanceId]}).then(res => {
            if(res.code === 200) {
              const params = {
                processInstanceId: row.processInstanceId,
                comment: row.comment,
                dealUserId: res.data[0].dealUserId,
                userId: this.loginUser.tyUserId
              }
              this.$fetch("third_doPassApprove", params).then(data => {
                if(data.code === 200) {
                  this.$message.success(data.msg)
                  this.getqueryPayVoucherDetailsByProcessInstanceId()
                } else {
                  this.$message.success(data.msg)
                }
              })
            }
          })
        })
        .catch(() => {
          console.log("取消");
        })
    },
    // 驳回凭证
    rejectClick(row) {
      console.log(row);
      this.rejectForm.processInstanceId = row.processInstanceId
      this.rejectForm.comment = row.comment
      this.rejectForm.userId = this.loginUser.tyUserId
      this.rejectModel = true
    },
    // 驳回保存
    save() {
      this.$refs.rejectForm.validate(async (valid) => {
        if(valid) {
          const data = await this.$fetch("third_doRejectApprove", this.rejectForm)
          if(data.code === 200) {
            this.$message.success(data.msg)
            this.getqueryPayVoucherDetailsByProcessInstanceId()
            this.rejectModel = false
          } else {
            this.$message.error('驳回异常')
          }
        }
      })
    },
    // 取消
    cancel() {
      this.rejectModel = false
    },
    // 编辑
    editClick(row) {
      if(this.allCollectionInformation.length > 1) {
        let price = 0
        this.allCollectionInformation.map(item => {
          if(item.processInstanceId !== row.processInstanceId) {
            price += item.money
          }
        })
        this.price = (this.orderDetails.payPrice * 100 - price * 100) / 100
      } else {
        this.price = this.orderDetails.payPrice
      }
      this.$refs.edit.init(row, (this.orderDetails.payPrice*100 - this.orderDetails.amountReceive*100)/100)
    },
    // 删除
    deleteClick(row) {
      this.$hqMessageBox({
      message:
        "确认删除凭证？",
      })
      .then(() => {
        this.$fetch("third_deleteVoucherByProcessInstanceId", {processInstanceIdList: [row.processInstanceId]}).then(res => {
          if(res.code === 200) {
            this.$message.success('删除成功！')
            this.getqueryPayVoucherDetailsByProcessInstanceId()
          }
        })
      })
      .catch(() => {
        console.log("取消");
      })
    },
    backClick() {
      this.$router.go(-1)
    }
  },
  watch: {
    "$route.query.id": {
      handler(value) {
        if(value) {
          this.getqueryPayVoucherDetailsByProcessInstanceId()
          this.getFindSettlementMethod()
          this.findLoanPlatform()
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .collection-detail {
    width: 100%;
    // min-height: 100vh;
    padding-bottom: 64px;
    background: #F5F5FA;
    .page-color {
      background: #fff;
    }
    .page-width {
      padding: 12px 0;
      width: 94%;
      margin: 0 auto;
      
      h2 {
        color: #333;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 12px;
      }
      .see-btn {
        margin: 30px 0 6px 0;
        display: flex;
        justify-content: center;
      }
    }

    .img-box {
      width: 96px;
      height: 54px;
      margin: 10px auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .go-back {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background: #fff;
    }
  }

  .remark {
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 这里是超出几行省略 */
    overflow: hidden;
  }

  ::v-deep.item {
    width: 500px;
  }
</style>
